
if( 'function' === typeof importScripts) {

importScripts('ngsw-worker.js');

self.addEventListener('install', event => {
  console.log('Service worker installing...');
  self.skipWaiting();
});

self.addEventListener('activate', event => {
  console.log('Service worker activating...');
  event.waitUntil(
    caches.keys().then(cacheNames => {
      return Promise.all(
        cacheNames.map(cache => {
          if (cache !== 'your-cache-name') {
            //console.log('Deleting old cache:', cache);
            return caches.delete(cache);
          }
        })
      );
    }).then(() => self.clients.claim())
  );
});

self.addEventListener('fetch', (event) => {
  console.log('--------------> CUSTOM FETCH handler : <' + event.request.url +">");
  // Perform custom fetch handling
});

self.addEventListener('push', event => {
  console.log('--------------> PUSH EVENT RECEIVED : <' + JSON.stringify(event) +">");

  const data = event.data ? event.data.text() : 'Notification';
  console.log('--------------> NOTIFICATION DATA : <' + JSON.stringify(data) +">");

  event.waitUntil(
    self.registration.showNotification("VABourlettis", {
      body: data,
      icon: 'assets/images/system/notification.jpg'
    }).then(() => {
      
      console.log('--------------> NOTIFICATION SHOW SUCCESFULY');
      self.clients.matchAll().then(clients => {
        clients.forEach(client => client.postMessage('Notification shown'));
      });
    }).catch(err => {
      console.log('--------------> NOTIFICATION ERROR : ' + err);
    })
  );
});

// Handle notification click
self.addEventListener('notificationclick', (event) => {
  event.notification.close();
  event.waitUntil(
    clients.openWindow(event.notification.data.url)
  );
});
}